import React, { useEffect, useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import Container from '../../Components/Container';
import Section from '../../Components/Section';
import styles from './styles.module.scss';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import './styles.module.scss';
import SwiperCore, {
    Autoplay,
    Pagination,
    Navigation,
    Keyboard,
    Mousewheel,
} from 'swiper';
SwiperCore.use([Autoplay, Pagination, Navigation, Keyboard, Mousewheel]);
const MarketingPromoters = (props) => {
    return (
        <Section className={`${styles.marketing_promoters}`}>
            <Container>
                {props.data && props.data.length > 0 ? (
                    <>
                        <Row>
                            <Col className={`${styles.textdiv}`}>
                                <h1 className={`${styles.title}`}>
                                    Backed by top firms & industry leaders
                                </h1>
                                <p>
                                    Easing NFT trading and capturing audiences
                                    wherever we go.
                                </p>
                            </Col>
                        </Row>
                        <Row className={`${styles.rowDiv}`}>
                            <Swiper
                                keyboard={true}
                                mousewheel={true}
                                watchOverflow={true}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                    },
                                    1280: {
                                        slidesPerView: 4,
                                    },
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    el: '.my-custom-pagination-div',
                                    clickable: true,
                                    renderBullet: (index, className) => {
                                        return `<span class="${className}"></span>`;
                                    },
                                }}
                                className="mySwiper"
                            >
                                {props.data.map((dat, index) => (
                                    <SwiperSlide key={index}>
                                        <Image
                                            alt={dat.image}
                                            src={dat.image}
                                            height="42.7px"
                                            width="277.9px"
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div
                                style={{ marginTop: '30px' }}
                                className="my-custom-pagination-div"
                            />
                        </Row>
                    </>
                ) : (
                    ''
                )}
            </Container>
        </Section>
    );
};

export default MarketingPromoters;
