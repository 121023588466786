import React from 'react';
// import useDarkOverlay from "../../Hooks/DarkOverlay";
import { UserActions as UA } from './Actions';
import { UserContext as UC } from './Context';

const UseUser = () => {
    const { userState, dispatch } = React.useContext(UC);

    const getUserProfile = (userData) => {
        dispatch({ type: UA.SET_USER_PROFILE_PAGEDATA, payload: userData });
    };

    const updateUserProfile = (userData) => {
        dispatch({ type: UA.UPDATE_PROFILE, payload: userData });
    };

    const signUserNonce = (userData) => {
        dispatch({ type: UA.SIGN_NOUNCE, payload: userData });
    };
    const clearUserProfileData = () => {
        dispatch({ type: UA.CLR_PROFILE_PAGE_DATA });
    };
    // const getUserprofileDataset =
    return {
        userState,
        getUserProfile,
        updateUserProfile,
        signUserNonce,
        clearUserProfileData,
    };
};

export default UseUser;
