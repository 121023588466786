import React from 'react';
import { UtilActions as UA } from './Actions';
import { UtilContext as UC } from './Context';

const UseUtil = () => {
    const { utilState, dispatch } = React.useContext(UC);

    const showConnectWalletPopup = () => {
        // useDarkOverlay(true);
        dispatch({ type: UA.SHOW_WALLET_BOX });
    };
    const hideConnectWalletPopup = () => {
        // useDarkOverlay(false);
        dispatch({ type: UA.HIDE_WALLET_BOX });
    };
    const showWrongNetworkPopup = () => {
        // useDarkOverlay(true);
        dispatch({ type: UA.SHOW_WRONG_NETWORK_BOX });
    };
    const hideWrongNetworkPopup = () => {
        // useDarkOverlay(false);
        dispatch({ type: UA.HIDE_WRONG_NETWORK_BOX });
    };
    const showTransactionPopup = (text, extra1 = '', extra2 = '') => {
        // useDarkOverlay(true);
        dispatch({
            type: UA.SHOW_TRANSACTION_BOX,
            payload: { main: text, extra1, extra2 },
        });
    };
    const hideTransactionPopup = () => {
        // useDarkOverlay(false);
        dispatch({ type: UA.HIDE_TRANSACTION_BOX });
    };
    const showPurchaseNftPopup = (data) => {
        // useDarkOverlay(true);
        dispatch({ type: UA.SHOW_PURCHASE_BOX, payload: data });
    };
    const hidePurchaseNftPopup = () => {
        // useDarkOverlay(false);
        dispatch({ type: UA.HIDE_PURCHASE_BOX });
    };
    const showOnIPFSPopup = (data) => {
        // useDarkOverlay(true);
        dispatch({ type: UA.SHOW_ON_IPFS, payload: data });
    };
    const hideOnIPFSPopup = () => {
        // useDarkOverlay(false);
        dispatch({ type: UA.HIDE_ON_IPFS });
    };
    const showCookieModel = () => {
        // useDarkOverlay(true);
        dispatch({ type: UA.SHOW_COOKIE_MODEL });
    };
    const hideCookieModel = () => {
        // useDarkOverlay(false);
        dispatch({ type: UA.HIDE_COOKIE_MODEL });
    };
    const setWalletType = (type) => {
        // useDarkOverlay(false);
        dispatch({ type: UA.SET_WALLET_TYPE, payload: type });
    };
    return {
        utilState,
        showConnectWalletPopup,
        hideConnectWalletPopup,
        showTransactionPopup,
        hideTransactionPopup,
        showPurchaseNftPopup,
        hidePurchaseNftPopup,
        showWrongNetworkPopup,
        hideWrongNetworkPopup,
        showOnIPFSPopup,
        hideOnIPFSPopup,
        showCookieModel,
        hideCookieModel,
        setWalletType,
    };
};

export default UseUtil;
