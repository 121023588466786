import dynamic from 'next/dynamic';
import React, { useState, useEffect } from 'react';
import TopCollectionCard from '../TopCollectionCard';
import TopCollectionHeading from '../TopCollectionHeading';
import styles from './styles.module.scss';
import { getTopCollection } from '../../../lib/NFT';
import useNFT from '../../../Contexts/NFT/useNFT';
import { getDeviceId } from '../../../Hooks/getDeviceId';
import UseAuth from '../../../Contexts/Auth/useAuth';

const Container = dynamic(() => import('../../Container'));
const Section = dynamic(() => import('../../Section'));
const TopCollectionSection = () => {
    const { authState } = UseAuth();

    const [topCollectionData, setTopCollectionData] = useState([]);
    const [blockChain, setBlockChain] = useState('');
    const [sortBy, setSortBy] = useState(2);
    const { getTopCollectionData, nftState } = useNFT();
    const nftTopCollectionFunc = async (sortBy, blockChain) => {
        let ParamsExplore = {
            sortByDate: sortBy,
            blockchain: blockChain,
            device_id: getDeviceId(),
        };
        let response = await getTopCollection(ParamsExplore);
        if (response?.status === 200) {
            setTopCollectionData(response.data.data);
            getTopCollectionData(response.data.data);
        }
    };
    useEffect(() => {
        nftTopCollectionFunc(sortBy, blockChain);
    }, [nftState?.updateTopCollectedData]);

    const onSortByChange = (value) => {
        setSortBy(value);
        nftTopCollectionFunc(value, blockChain);
    };
    const onBlockChainChange = (value) => {
        setBlockChain(value);
        nftTopCollectionFunc(sortBy, value);
    };
    return (
        <Section className={`${styles.referenalbanner}`}>
            <Container>
                <TopCollectionHeading
                    sortBy={sortBy}
                    blockChain={blockChain}
                    onSortByChange={onSortByChange}
                    onBlockChainChange={onBlockChainChange}
                />
                <TopCollectionCard
                    data={topCollectionData}
                    sortBy={sortBy}
                    blockChain={blockChain}
                    nftTopCollectionFunc={nftTopCollectionFunc}
                />
            </Container>
        </Section>
    );
};

export default TopCollectionSection;
