import React from 'react';
import SVG from 'react-inlinesvg';
import MainTitle from '../../Section/Title/Main';
import styles from './styles.module.scss';
const TopCollectionHeading = ({
    sortBy,
    blockchain,
    onSortByChange,
    onBlockChainChange,
    ...props
}) => {
    return (
        <div className={`${styles.collection_separates}`}>
            <MainTitle title={'Top Collections'} />
            <div className={`${styles.collection_tabs}`}>
                <input
                    onChange={() => {
                        onSortByChange(1);
                    }}
                    type="radio"
                    className={`${styles.tabs_radio}`}
                    name="tabs-example"
                    id="tab1"
                    checked={sortBy === 1 ? 'checked' : ''}
                />
                <label htmlFor="tab1" className={`${styles.tabs_label}`}>
                    1 Day
                </label>
                <input
                    onChange={() => {
                        onSortByChange(2);
                    }}
                    type="radio"
                    className={`${styles.tabs_radio}`}
                    name="tabs-example"
                    id="tab2"
                    checked={sortBy === 2 ? 'checked' : ''}
                />
                <label htmlFor="tab2" className={`${styles.tabs_label}`}>
                    7 Days
                </label>
                <input
                    onChange={() => {
                        onSortByChange(3);
                    }}
                    type="radio"
                    className={`${styles.tabs_radio}`}
                    name="tabs-example"
                    id="tab3"
                    checked={sortBy === 3 ? 'checked' : ''}
                />
                <label htmlFor="tab3" className={`${styles.tabs_label}`}>
                    30 Days
                </label>
            </div>
            <div className="position-relative">
                <select
                    onChange={(event) => {
                        onBlockChainChange(event.target.value);
                    }}
                    className={`${styles.collection_select}`}
                >
                    <option value={''}>All</option>
                    <option
                        value={'ethereum'}
                        selected={blockchain === 'ethereum' ? 'selected' : ''}
                    >
                        Ethereum
                    </option>
                    <option
                        value={'polygon'}
                        selected={blockchain === 'ethereum' ? 'selected' : ''}
                    >
                        Polygon
                    </option>
                </select>
                {/* <SVG
          src="/assets/vectors/Vector_right_arror.svg"
          width="12px"
          height="12px"
          className={`${styles.selectIcon}`}
        /> */}
            </div>
        </div>
    );
};

export default TopCollectionHeading;
